import React, { useState, useEffect, useCallback } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
// import HeaderLinks from "components/Header/HeaderLinks.js";
// import SectionBasics from "./Sections/SectionBasics.js";
// import SectionNavbars from "./Sections/SectionNavbars.js";
// import SectionTabs from "./Sections/SectionTabs.js";
// import SectionPills from "./Sections/SectionPills.js";
// import SectionNotifications from "./Sections/SectionNotifications.js";
// import SectionTypography from "./Sections/SectionTypography.js";
// import SectionJavascript from "./Sections/SectionJavascript.js";
// import SectionCarousel from "./Sections/SectionCarousel.js";
// import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
// import SectionLogin from "./Sections/SectionLogin.js";
// import SectionExamples from "./Sections/SectionExamples.js";
// import SectionDownload from "./Sections/SectionDownload.js";
import LessBasic from "./Sections/LessBasic.js";

import styles from "assets/jss/material-kit-react/views/components.js";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
//  import { photoss } from "./MyComponents/Photos";
//import Img from 'react-image'
import { debounce } from "../../utils/debounce";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles(styles);
const useStyless = makeStyles({
  testukas: {
    color: "red",
  },
  zazz: {
    zIndex: "9999999 !important",
  },
});

export default function Components(props) {
  //const [currentImage, setCurrentImage] = useState(0);
  //const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const PAGE_SIZE = 10;

  const [state, setstate] = useState({
    total: 40,
    images: [],
  });

  console.log("state:");
  console.log(state);

  const classes = useStyles();
  const { ...rest } = props;

  async function get(url) {
    let response = await fetch(url);
    return await response.json();
  }

  useEffect(() => {
    console.log(window.origin);

    async function getData() {
      let total = await get(`${window.origin}/PicCount`);
      let imgs = await get(
        `${window.origin}/Pictures?count=${PAGE_SIZE}`
      );

      console.log(`total = ${total}`);
      console.log(imgs);

      setstate({
        ...state,
        total: total,
        images: imgs,
      });
    }

    getData();
  }, []);

  const columnsCount = window.innerWidth > 760 ? 3 : 1;

  const fetchMoreData = async () => {
    console.log("daro fetch more data!!!!!");

    let imgs = await get(
      `${window.origin}/Pictures?count=${PAGE_SIZE}&skip=${state.images.length}`
    );

    setstate({
      ...state,
      images: state.images.concat(imgs),
    });
  };

  return state.images.length == 0 ? (
    <div></div>
  ) : (
    <div>
      <Header
        brand="Kačiukai"
        //rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <div>
        <InfiniteScroll
          dataLength={state.images.length}
          next={fetchMoreData}
          hasMore={state.images.length < state.total}
          loader={<h4>Loading...</h4>}
          height={window.height}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Kačiukai baigėsi :O</b>
            </p>
          }
        >
          <div>
            <Parallax image={require("assets/img/dark-kitty.jpg")}>
              <div className={classes.container}>
                <GridContainer>
                  <GridItem>
                    <div className={classes.brand}>
                      <h1 className={classes.title}>Kačiukų</h1>
                      <h3 className={classes.subtitle}>
                        galerija tikriems fanams jau greitai
                      </h3>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
              <Gallery
                photos={state.images}
                /* onClick={openLightbox} */ direction={"column"}
                className="testClas"
                columns={columnsCount}
                margin={15}
              />
            </div>
            {/* <LessBasic /> */}
            <Footer />
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}
